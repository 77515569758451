<script lang="ts">
	import { browser } from '$app/environment'
	import { MediaType } from '$lib/graphql/enums'
	import { constructCdnUrl, constructVideoThumbnailUrl } from '$lib/utils/cloudflare-loader'

	interface Props {
		mediaType: MediaType
		cloudflareID: string
		animationDelay?: number
		animationDuration?: number
		isLoaded?: boolean
		onload?: () => void
	}

	let {
		mediaType,
		cloudflareID,
		animationDelay = 0,
		animationDuration = 200,
		isLoaded = false,
		onload,
	}: Props = $props()

	let imageLoaded = $state(false)

	function handleImageLoad() {
		imageLoaded = true
		onload?.()
	}

	function handleImageError(err: ErrorEvent) {
		console.warn('Image load error:', err)
	}
</script>

<div class="grid grid-cols-[100%]">
	<div class="z-20 flex-col area">
		{#if mediaType === MediaType.Image}
			<img
				class="h-56 w-[150px] rounded-lg object-cover border-2 border-transparent transition-opacity duration-200"
				style={`opacity: ${imageLoaded ? 1 : 0}; transition-delay: ${animationDelay}ms;`}
				src={constructCdnUrl(cloudflareID)}
				alt="highlight"
				onload={handleImageLoad}
				onerror={(e) => handleImageError(e as unknown as ErrorEvent)}
			/>
		{:else if mediaType === MediaType.Video}
			<img
				class="h-56 w-[150px] rounded-lg object-cover border-2 border-transparent transition-opacity duration-200"
				style={`opacity: ${imageLoaded ? 1 : 0}; transition-delay: ${animationDelay}ms;`}
				src={constructVideoThumbnailUrl(cloudflareID)}
				alt="highlight"
				onload={handleImageLoad}
				onerror={(e) => handleImageError(e as unknown as ErrorEvent)}
			/>
		{/if}
	</div>
	<div
		class="z-10 flex-col area transition-opacity duration-200"
		style={`opacity: ${imageLoaded ? 0 : 1}; transition-delay: ${animationDelay}ms;`}
	>
		<div class="h-56 w-[150px] rounded-lg bg-brand-gray-4/20 animate-pulse"></div>
	</div>
</div>

<style lang="postcss">
	.area {
		grid-area: 1 / 1 / 2 / 2;
	}
</style>
